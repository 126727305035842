import React, { useState } from "react";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Button,
  Stack,
  Container,
} from "@mui/material";
import {
  Instagram,
  WhatsApp,
  LinkedIn,
  FacebookOutlined,
  ArrowBackIos,
  Menu,
} from "@mui/icons-material/";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import CustomButton from "./CustomButton";

// Ancho del cajón lateral en píxeles
const drawerWidth = "100%";

/**
 * A button component for social media links.
 * Renders a button with the social media icon and tracks clicks using Google Analytics.
 * @param {Object} props - The component props.
 * @param {Object} props.social - The social media object.
 * @param {string} props.social.name - The name of the social media platform.
 * @param {string} props.social.url - The URL to the social media platform.
 * @param {JSX.Element} props.social.icon - The icon representing the social media platform.
 * @returns {JSX.Element} A button that links to the specified social media platform.
 * @function
 */
const SocialButton = ({ social }) => (
  <Button
    key={social.name}
    name={social.name}
    title={social.name}
    variant="text"
    rel="nofollow"
    target="_blank"
    href={social.url}
    onClick={() => {
      ReactGA.event({
        category: "Interaccion",
        action: `Visit a ${social.name}`,
        label: social.name,
        value: 1,
      });
    }}
  >
    {social.icon}
  </Button>
);

const Navbar = (props) => {
  const { window, phonewidth } = props;
  /**
   * Function to navigate between routes.
   * @type {Function}
   */
  const navigate = useNavigate();

  /**
   * State variable to control mobile menu visibility.
   * @type {[boolean, Function]}
   */
  const [mobileOpen, setMobileOpen] = useState(false);

  /**
   * Toggles the state of the mobile drawer.
   * This function inverts the current state of `mobileOpen`.
   *
   * @function
   */
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  /**
   * Handles the click event for navigation items.
   * Updates the active button state, tracks the event with Google Analytics,
   * and navigates to the specified URL. Also toggles the mobile drawer state.
   *
   * @param {Object} item - The item associated with the click event.
   * @param {string} item.name - The name of the item.
   * @param {string} item.url - The URL to navigate to.
   *
   * @function
   */
  const handleClickPages = (item) => {
    setActiveButton(item.name);
    ReactGA.event({
      category: "Interaccion",
      action: `Visit a ${item.name}`,
      label: item.name,
      value: 1,
    });
    navigate(item.url);
    setMobileOpen(false);
  };

  /**
   * State variable to control change visibility icon.
   * @type {[boolean, Function]}
   */
  const [loading, setLoading] = useState(false);

  /**
   * Handles the click event for the help button in the navbar.
   * Tracks the event with Google Analytics and opens a help link in a new tab.
   *
   * @function
   */
  const handleClickHelp = () => {
    setLoading(true);
    ReactGA.event({
      category: "Interaccion",
      action: "¿Necesitas ayuda? - navbar",
      label: "¿Necesitas ayuda?",
      value: 1,
    });
  };

  /**
   * Handles the click event for the download app button in the navbar.
   * Tracks the event with Google Analytics and opens a download link in a new tab.
   *
   * @function
   */
  const handleClickDownloadApp = () => {
    ReactGA.event({
      category: "Interaccion",
      action: "¡Descarga ya!-navbar",
      label: "¡Descarga ya!",
      value: 1,
    });
  };

  /**
   * State variable to keep track of the currently active button.
   * @type {[number|null, Function]}
   */
  const [activeButton, setActiveButton] = useState(null);

  /**
   * The drawer component containing navigation items, social buttons, and custom action buttons.
   * It is structured using Material-UI components such as Stack, Box, List, and CustomButton.
   *
   * @constant
   * @type {JSX.Element}
   */
  const drawer = (
    <>
      <Stack
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        spacing={12}
      >
        <Button color="primary" onClick={handleDrawerToggle}>
          <ArrowBackIos color="primary" />
        </Button>
        <Box
          onClick={handleDrawerToggle}
          component="img"
          alt="Movicare_logo"
          src="https://movicaremx.com/IMG/Sitioweb/Favicon_orange.png"
          width={50}
          height={50}
        />
      </Stack>

      <Box sx={{ textAlign: "center" }}>
        <List>
          {navItems.map((item, idx) => (
            <ListItem key={idx} disablePadding>
              <ListItemButton onClick={() => handleClickPages(item)}>
                <ListItemText
                  primaryTypographyProps={design.txt}
                  primary={item.name}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {sociales.map((social) => (
          <SocialButton key={social.name} social={social} />
        ))}
        <CustomButton
          width="75%"
          backgroundcolor="#00528CA6"
          colortexthover="rgba(68, 150, 210, 0.55)"
          backgroundcolorhover="#FFFFFF"
          colortexto="#FFFFFF"
          bordercolor="#4496D2"
          type="submit"
          fontSize={19}
          onClick={handleClickHelp}
          // ¡Hola MoviCare! ¿Podrían ayudarme a cotizar un servicio con ustedes? Por favor ☺️ 🌐
          href="https://wa.link/dgvzl7"
          variant="contained"
        >
          ¿Necesitas ayuda?{" "}
          {loading ? (
            <WhatsApp fontSize="medium" sx={design.icon} />
          ) : (
            <WhatsApp fontSize="medium" style={design.iconWhite} />
          )}
        </CustomButton>
        <CustomButton
          width="75%"
          backgroundcolor="#00528CA6"
          colortexthover="rgba(68, 150, 210, 0.55)"
          backgroundcolorhover="#FFFFFF"
          colortexto="#FFFFFF"
          bordercolor="#4496D2"
          fontSize={19}
          type="submit"
          href="https://uqr.to/s8ju"
          variant="contained"
          onClick={handleClickDownloadApp}
        >
          ¡Descarga ya!{" "}
          <Box
            component="img"
            alt="Movicare_logo"
            src="https://movicaremx.com/IMG/Sitioweb/Favicon_white.png"
            width={32}
            height={26}
          />
        </CustomButton>
      </Box>
    </>
  );

  /**
   * The container for the drawer. Determines where the drawer should be attached in the DOM.
   *
   * @constant
   * @type {function|undefined}
   */
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <AppBar
      component="nav"
      sx={{ backgroundColor: " #FEFCFA" }}
      position="fixed"
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { xs: "flex", sm: "none" } }}
          >
            <Menu sx={design.icon} />
          </IconButton>
          <Box
            onClick={() => navigate("/")}
            component="img"
            alt="Movicare_servicios_médicos_logo"
            src="https://movicaremx.com/IMG/Sitioweb/Logo_web_orange.png"
            width={phonewidth ? "20%" : "10%"}
            height={phonewidth ? "6%" : "3%"}
            paddingY={1}
          />

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <Box sx={design.navContainer}>
              {navItems.map((item, index) => (
                <Button
                  key={`${item.name}-${index}`}
                  sx={{
                    ...design.txt,
                    fontSize: 20,
                    border:
                      activeButton === item.name
                        ? "2px solid #00528CA6"
                        : "2px solid transparent",
                    borderRadius: 3,
                    boxSizing: "border-box",
                  }}
                  onClick={() => handleClickPages(item)}
                >
                  {item.name}
                </Button>
              ))}
              {sociales.map((social, index) => (
                <SocialButton key={`${social.name}-${index}`} social={social} />
              ))}
            </Box>
          </Box>

          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={design.drawerContain}
          >
            {drawer}
          </Drawer>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
            }}
          >
            {navItems.map((item, index) => (
              <Button
                key={`${item.name}-${index}`}
                sx={{
                  ...design.txt,
                  fontSize: 20,
                  border:
                    activeButton === item.name
                      ? "2px solid #00528CA6"
                      : "2px solid transparent",
                  borderRadius: 3,
                  marginRight:2,
                  boxSizing: "border-box",
                  "&:hover": {
                    backgroundColor: "rgba(68, 150, 210, 0.55)", 
                  },
                }}
                onClick={() => handleClickPages(item)}
              >
                {item.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, justifyContent: "flex-end" }}>
            {sociales.map((social, index) => (
              <SocialButton key={`${social.name}-${index}`} social={social} />
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;

/**
 * design es un objeto que contiene estilos personalizados para el texto dentro del tooltip.
 * @type {Object}
 */
const design = {
  icon: {
    color: "#00528CA6",
    fontWeight: "bold",
  },
  drawerContain: {
    display: { xs: "block", sm: "none", md: "none", lg: "none", xl: "none" },
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: drawerWidth,
      backgroundImage:
        "url(https://movicaremx.com/IMG/Sitioweb/Onda_Nav_Rosa.png)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top",
      backgroundOrigin: "padding-box",
      backgroundSize: "cover",
    },
  },
  navContainer: {
    display: {
      xs: "none",
      sm: "block",
      marginLeft: "-10%",
      maxWidth: "95%",
    },
  },
  iconWhite: {
    color: "#ffffff",
    fontWeight: "bold",
  },
  txt: {
    fontFamily: "Lexend",
    color: "#00528CA6",
    fontWeight: 400,
    textAlign: "center",
    textDecoration: "none",
    textTransform: "none",
  },
};

// Elementos de navegación en el cajón lateral
const navItems = [
  { name: "Inicio", url: "/" },
  { name: "Nosotros", url: "/nosotros" },
  { name: "Blog", url: "/Blog" },
  {
    name: "MoviLabs",
    url: "/Servicios-medicos-para-laboratorios-en-ciudad-de-mexico-y-area-metropolitana",
  },
  {
    name: "MoviEmpresas",
    url: "/Servicios-medicos-para-empresas-en-ciudad-de-mexico-y-area-metropolitana",
  },
];

// Enlaces a plataformas sociales
const sociales = [
  {
    icon: <FacebookOutlined fontSize="medium" style={design.icon} />,
    name: "Facebook",
    url: "https://www.facebook.com/MoviCareMx/",
  },
  {
    icon: <Instagram fontSize="medium" style={design.icon} />,
    name: "Instagram",
    url: "https://www.instagram.com/movicare_mx/?hl=es-la",
  },
  {
    icon: <LinkedIn fontSize="medium" style={design.icon} />,
    name: "LinkedIn",
    url: "https://www.linkedin.com/company/movicaremx/",
  },
];
